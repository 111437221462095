import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 归档列表
export const contractArchiveInfoPage = (data) => {
    return request({
        url: apiCrmHost + '/contract/archive/info/page',
        method: 'post',
        data
    });
};
// 归档停用
export const contractArchiveInfoRemove = (data) => {
    return request({
        url: apiCrmHost + '/contract/archive/info/remove',
        method: 'delete',
        data
    });
};
