import dayjs from 'dayjs';
import store from '@/store/index';
const btnPermissionsArr = store.getters.btnPermissions;
const pickerOptions = {
    shortcuts: [{
        text: '最近一周',
        onClick(picker) {
            const DAYS_A_WEEK = 7;
            const start = dayjs().startOf('day').subtract(DAYS_A_WEEK - 1, 'd').toDate();
            const end = dayjs().endOf('day').toDate();
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近一个月',
        onClick(picker) {
            const start = dayjs().startOf('day').subtract(1, 'M').toDate();
            const end = dayjs().endOf('day').toDate();
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近三个月',
        onClick(picker) {
            const start = dayjs().startOf('day').subtract(3, 'M').toDate();
            const end = dayjs().endOf('day').toDate();
            picker.$emit('pick', [start, end]);
        }
    }]
};
export const config = {
    name: 'costomerSeries',
    searchFields: [
        {
            name: '单据编码',
            value: 'contractCode',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '合同归档码',
            value: 'archiveCode',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '归档码状态',
            value: 'enableFlag',
            type: 'select',
            optionNum: 'SYS_BMS_EX_RELATION_STATUS',
            isFixed: true,
            span: 3
        },
        {
            name: '创建时间',
            value: 'createTime',
            isFixed: true,
            type: 'time',
            time: 'daterange',
            startPlaceholder: '创建时间从',
            endPlaceholder: '创建时间到',
            pickerOptions,
            span: 6
        }
    ],
    selection: false,
    index: false,
    tableData: {
        columns: [
            {
                label: '单据编码',
                minWidth: 160,
                prop: 'contractCode',
                sortable: true
            },
            {
                label: '合同归档码',
                minWidth: 160,
                prop: 'archiveCode',
                sortable: true
            },
            {
                label: '回收份数',
                minWidth: 110,
                prop: 'receivedCopies',
                sortable: true
            },
            {
                label: '是否收到纸质合同',
                prop: 'isReceived',
                type: 'select',
                optionsKey: 'SYS_YES_NO',
                minWidth: 150
            },
            {
                label: '归档码状态',
                prop: 'enableFlag',
                type: 'select',
                optionsKey: 'SYS_BMS_EX_RELATION_STATUS',
                minWidth: 150
            },
            {
                label: '备注',
                prop: 'illustrate',
                minWidth: 150
            },
            {
                label: '创建人mip',
                prop: 'createUserCode',
                minWidth: 150
            },
            {
                label: '创建人',
                prop: 'createUserName',
                minWidth: 150
            },
            {
                label: '创建时间',
                prop: 'createTime',
                sortable: true,
                minWidth: 150
            },
            {
                label: '修改人mip',
                prop: 'updateUserCode',
                minWidth: 150
            },
            {
                label: '修改人',
                prop: 'updateUserName',
                minWidth: 150
            },
            {
                label: '修改时间',
                prop: 'updateTime',
                sortable: true,
                minWidth: 150
            }
        ]
    },
    PANELMAX: 8,
    LASTFOUR: 4,
    LASTSIX: 6,
    actions: { // 表格操作列的配置
        fixedWidth: 80, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
        list: [
            {
                label: '停用',
                event: 'stop',
                displayRule: (row) => {
                    return +row.enableFlag === 1 && btnPermissionsArr.includes('1100000001');
                }
            }
        ]
    }
};
