<template>
    <div class="costomer-series-manage">
        <Paper class="costomer-series-manage-paper">
            <list-layout-paper>
                <template slot="header-search" class="searchBox">
                    <search-box
                        ref="searchBox"
                        :search-key="config.name"
                        :fields="config.searchFields"
                        :showResetBtn="true"
                        @search-change="contractList.searchList"
                    />
                </template>
                <template slot="header-button">
                    <lotsButton
                        v-has="authorityCode.export"
                        type="primary"
                        size="mini"
                        title="导出">
                        <report-export
                            #default="{ submit }"
                            reportCode="REPORT_CRM_CONTRACT_ARCHIVE">
                            <span @click="handleExport(submit)">导出</span>
                        </report-export>
                    </lotsButton>
                    <lotsButton
                        type="primary"
                        size="mini"
                        title="刷新"
                        @click="refresh"
                        plain>
                        <span class="icon iconfont icon-refresh"></span>
                    </lotsButton>
                    <el-tooltip effect="dark" :content="'列配置'" placement="bottom">
                        <el-button
                        icon="iconfont icon-list_install"
                        type="primary"
                        size="mini"
                        :title="'列配置'"
                        @click="showColumnConfigDialog"
                        plain />
                    </el-tooltip>
                </template>
                <!-- 表格 -->
                <template v-slot:list="{ tableHeight }">
                    <table-box
                        ref="clientOrder"
                        :height="tableHeight"
                        v-loading="contractList.tableLoading"
                        :selection="config.selection"
                        :index="config.index"
                        :columns="config.tableData.columns"
                        :rows="contractList.totalData"
                        :actions="config.actions"
                        @action-click="actionClick"
                        @row-dblclick="rowDBclick">
                    </table-box>
                </template>
                <template slot="footer">
                    <lots-pagination
                        @size-change="contractList.sizeChange"
                        :current-page.sync="contractList.pageNo"
                        @current-change="contractList.pageChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="contractList.pageTotal" >
                    </lots-pagination>
                </template>
            </list-layout-paper>
            <!-- 表格列配置弹框 -->
            <column-config
                :pageID="config.name"
                :initColumns="config.tableData.columns"
                ref="otpColumnConfig"
                @header-change="config.tableData.columns = $event"
            />
        </Paper>
    </div>
</template>
<script>
import { ref, reactive, onMounted, getCurrentInstance } from '@vue/composition-api';
import Paper from '@/components/core/Paper';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import searchBox from '@/components/lots/searchBox';
import tableBox from '@/components/lots/tableBox/Index';
import { contractArchiveInfoPage, contractArchiveInfoRemove } from '@mdm/api/contractManage/contractArchiveApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { config as configStatic } from './config';
import utils from '@/components/utils/common.js';
import lotsButton from '@/components/lots/lotsButton';
import columnConfig from '@/components/lots/columnConfig/Index';
import store from '@/store';
import Bus from '@/utils/bus.js';
import reportExport from '@/components/lots/reportExportPlugin';
import _ from 'lodash';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
const { MAGIC_NUMBER } = utils;
export default {
    name: 'customer',
    components: {
        Paper,
        listLayoutPaper,
        searchBox,
        tableBox,
        lotsPagination,
        lotsButton,
        columnConfig,
        reportExport
    },
    props: {},
    // eslint-disable-next-line max-lines-per-function
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractArchive);
        const { user } = store.getters;
        const config = reactive(configStatic);
        class ContractListClass extends AdvanceListClass {
            beforeGetList(condition) {
                return searchDataFormat(condition);
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list;
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const contractList = new ContractListClass({
            Api: {
                listApi: contractArchiveInfoPage, // 查询接口
                deleteApi: contractArchiveInfoRemove // 删除行接口
            }
        });
        onMounted(() => {
            const queryParams = {
                createTime: utils.defaultDate('90') // 默认选中前90天(当前日期23.59.59 到15天前的0.0.0)时间范围
            };
            setupContext.refs.searchBox.setQueryParams(queryParams);
            setupContext.refs.searchBox.submitSearch();
        });
        const refresh = () => {
            contractList.getList();
        };
        const searchDataFormat = (condition) => {
            if (condition.createTime && condition.createTime.length) {
                condition.startTime = utils.formatDateTime(condition.createTime[0]);
                condition.endTime = utils.formatDateTime(condition.createTime[1]);
                Reflect.deleteProperty(condition, 'createTime');
            }
            if (condition.selectList && condition.selectList.length) {
                condition.crmUserCodes = condition.selectList[0].userCode;
            }
            Reflect.deleteProperty(condition, 'selectList');
            Reflect.deleteProperty(condition, 'userName');
            condition.userCode = user.userCode;
            condition.isNew = condition.isNew ? +condition.isNew : null;
            Object.keys(condition).forEach(v => {
                !condition[v] && condition[v] !== 0 && (condition[v] = undefined);
            });
            return condition;
        };
        const holePageLoading = ref(false);
        const otpColumnConfig = ref(null);
        const showColumnConfigDialog = () => {
            otpColumnConfig.value.show(true);
        };
        const actionClick = (event, row) => {
            if (event === 'stop') {
                contractList.deleteRow(row, {
                    msg: '请确认是否清除该合同归档码？该操作不可恢复',
                    successMsg: '停用成功'
                });
            }
        };
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const rowDBclick = (row) => {
            ctx.$router.push('/mod/mdm/contract-manage');
            setTimeout(() => {
                Bus.$emit('viewContractDetail', { contractCode: row.contractCode });
            }, MAGIC_NUMBER.TWO_HUNDRED);
        };
        const handleExport = async(submit) => {
            await setupContext.refs.searchBox.submitSearch(); // 解决输入参数不生效问题
            const { value } = contractList.searchModel;
            const newData = _.cloneDeep(value); // 解决搜索参数被清空的问题
            const params = {
                ...searchDataFormat(newData)
            };
            Object.keys(params).forEach(v => {
                if (params[v] === undefined || params[v] === null || params[v] === '') {
                    delete params[v];
                }
            });
            submit(params);
        };
        return {
            config,
            holePageLoading,
            contractList,
            searchDataFormat,
            refresh,
            otpColumnConfig,
            showColumnConfigDialog,
            actionClick,
            rowDBclick,
            handleExport,
            authorityCode
        };
    }
};
</script>

<style lang="less">
.costomer-series-manage {
    width: 100%;
    .costomer-series-manage-paper {
        height: 100%;
    }
    .list-layout-wrapper {
        height: 100%;
    }

    .el-tabs {
        width: 100%;
    }
    .flex-layout .list-main {
        overflow: auto;
    }
    .flex-layout .table {
        overflow-y: auto;
    }
    .layout-content.costomer-series-manage-paper {
        padding: 0px 0px 0px;
    }
    .col-append-box {
        display: flex;
        align-items: center;
        .col-append-btn {
            padding-bottom: 3px;
            padding-left: 3px;
            .eye-btn {
                display: inline-block;
                width: 10px;
                height: 10px;
                cursor: pointer;
            }
        }
    }
}
</style>
